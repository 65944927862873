import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Container,
  Avatar,
  useColorModeValue,
  Divider,
  Button
} from '@chakra-ui/react';
import { BiChevronRight } from 'react-icons/bi'
import { PopupButton } from '@typeform/embed-react'

const TypeForm = () => {
  return (
    <PopupButton id="XJpWYRYC" style={{ fontSize: 20 }} className="my-button">
      <Box
        py='6'
        >
        <Button 
          rightIcon={<BiChevronRight />} 
          size='lg'
          bg='#1A1B48' 
          color='whiteAlpha.900'
          _hover={{
            backgroundColor: 'white',
            // backgroundColor: 'transparent',
            color: 'black',
            // border: '#1A1B48',
            borderColor: '#1A1B48'
        }}>
          More Info Please
        </Button>
      </Box>
    </PopupButton>
  )
}

const Testimonial = ({ children })=> {
  return <Box>{children}</Box>;
};

const TestimonialContent = ({ children }) => {
  return (
    <Stack
      // bg={useColorModeValue('gray.200', 'gray.800')}
      bg='#C2CCD0'
      boxShadow='lg'
      p={8}
      rounded='xl'
      align='center'
      pos='relative'
      borderColor='#1A1B48'
      borderWidth='solid, 1px'
      border='solid'
      _after={{
        content: `""`,
        w: 0,
        h: 0,
        // borderLeft: 'solid transparent',
        // borderLeftWidth: 16,
        // borderRight: 'solid transparent',
        // borderRightWidth: 16,
        // borderTop: 'solid',
        // borderTopWidth: 16,
        // borderTopColor: useColorModeValue('white', 'gray.800'),
        pos: 'absolute',
        bottom: '-16px',
        left: '50%',
        transform: 'translateX(-50%)',
      }}>
      {children}
    </Stack>
  );
};

const TestimonialHeading = ({ children }) => {
  return (
    <Heading as='h3' fontSize='2xl' fontWeight='extrabold'>
      {children}
    </Heading>
  );
};

const TestimonialText = ({ children }) => {
  return (
    <Text
      textAlign={{md: 'justify', lg:'center'}}
      // color={useColorModeValue('gray.600', 'gray.400')}
      color={useColorModeValue('black', 'gray.600')}
      fontSize={{ md:'x-small', lg:'md'}}
      >
      {children}
    </Text>
  );
};

const TestimonialAvatar = ({src,name,title }) => {
  return (
    <Flex align='center' mt={8} direction='column'>
      <Avatar src={src} alt={name} mb={2} />
      <Stack spacing={-1} align='center'>
        <Text fontWeight={600}>{name}</Text>
        <Text fontSize='sm' color={useColorModeValue('gray.600', 'gray.400')}>
          {title}
        </Text>
      </Stack>
    </Flex>
  );
};

export default function Testimonials() {
  return (
    <Box>
      <Heading as='h1' size='2xl' textAlign='center' fontWeight='extrabold' pt='3'>Don't Take Our Word For It</Heading>
    {/* <Box bg={useColorModeValue('gray.100', 'gray.700')}> */}
      <Container maxW='7xl' pt='8' as={Stack} spacing={12}>
        {/* <Stack spacing={0} align='center'>
          <Heading>Our Clients Speak</Heading>
          <Text>We have been working with clients around the world</Text>
        </Stack> */}
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 10, md: 4, lg: 10 }}>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Alorica</TestimonialHeading>
              <TestimonialText>
              According to Weber Shandwick data, executives believe their companies' 
              reputation accounts for 63% of their market value on average.
              A combination of humans and bots can help protect your business.
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src='https://campfire-landing-page-assets.s3.us-east-2.amazonaws.com/forbes.png'
              name='Colson Hillier'
              title='CEO at Alorica'
            />
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Trip Advisor</TestimonialHeading>
              <TestimonialText>
              97% of Business Owners say online reputation management is important to their business
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src='https://campfire-landing-page-assets.s3.us-east-2.amazonaws.com/tripadvisor.png'
              name='Trip Advisor'
              title='2018 Hospitality Sector Report'
            />
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>ICCO</TestimonialHeading>
              <TestimonialText>
              When consumers make a conscious (or unconscious) choice between brands, for example, 
              they are affected by a whole nexus of influences which can be broadly summarized as "reputation."
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src='https://campfire-landing-page-assets.s3.us-east-2.amazonaws.com/icco.png'
              name='Paul Blanchard'
              title='ICCO World PR Report 2020'
            />
          </Testimonial>
        </Stack>
        <Divider />
      </Container>
      <Box align='center'>
        <TypeForm />
      </Box>
      <Heading as='h1' size='2xl' textAlign='center' fontWeight='extrabold' pt='3'>How BizAlertPro Can Help</Heading>
    </Box>
  );
}