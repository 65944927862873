import React from 'react'
import LandingPageLayout from '../components/LandingPageLayout';



export const Landing = (props) => {
  return (
    <>
      <LandingPageLayout />
    </>
  )
}
