import React from 'react';
import { Outlet, Link, useLoaderData } from "react-router-dom";
import getBrands from "../brands";
import HeaderTwo from '../components/HeaderTwo';
import { Landing } from '../features/Landing';

export async function loader() {
  const brands = await getBrands();
  return { brands };
}

const Root = () => {
  const { brands } = useLoaderData();
  return (
    <>
      <Landing />

    </>
  );
}

export default Root;