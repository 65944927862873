import React from "react";
import { Flex, Container} from "@chakra-ui/react";
import NavBar from "./NavBar";
import HeaderTwo from "./HeaderTwo";
import SocialProof from "./SocialProof";
import Footer from "./Footer";
import ProductFeatures from "./ProductFeatures";
import Testimonials from "./Testimonials";
import Features from "./Features";

export default function LandingPageLayout(props) {
  return (
    <Container 
      maxW='container.2xl'
      p={0}
      bgGradient={'linear(to-br, hsla(197, 14%, 57%, 1) 0%, hsla(192, 17%, 94%, 1) 100%)'}
    >
      {/* <Flex 
      // h={{ base: 'auto', md:'100vh'}}
      // py={[ 0, 10, 20]}
      direction='column' 
      // direction={{ base: 'column-reverse', md: 'row'}}
      bgGradient={'linear(90deg, hsla(197, 14%, 57%, 1) 0%, hsla(192, 17%, 94%, 1) 100%)'}
      // bgGradient={'linear(white 0%, #FF5700 40%, #EFF7FF 90%)'}
      > */}
        <NavBar />
        <HeaderTwo />
        <Testimonials />
        <Features />
        <Footer />
        {/* <SocialProof /> */}
        {/* <ProductFeatures /> */}
      {/* </Flex> */}
    </Container>
    // <Flex>
    //   direction='row'
    //   bgGradient={'linear(90deg, hsla(197, 14%, 57%, 1) 0%, hsla(192, 17%, 94%, 1) 100%)'}
    // >
    //   <NavBar />
    //   <HeaderTwo />
    // </Flex>
    // <Flex
    //   direction="column"
    //   // align="center"
    //   maxW={[
    //     "auto",    // 0-30em
    //     "auto",    // 30em-48em
    //     "auto",    // 48em-62em
    //     'auto',
    //     // "1200px",  // 62em+
    //   ]}
    //   m="0 auto"
    //   {...props}
    // >
    //   {props.children}
    //   {/* <NavBarContainer /> */}
    //   <NavBar />
    //   {/* <Hero /> */}
    // </Flex>
  )
}