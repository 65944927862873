import { extendTheme } from "@chakra-ui/react";
import { withProse } from "@nikolovlazar/chakra-ui-prose";

const colors = {
  primary: {
    100: "#E5FCF1",
    200: "#27EF96",
    300: "#10DE82",
    400: "#0EBE6F",
    500: "#0CA25F",
    600: "#0A864F",
    700: "#086F42",
    800: "#075C37",
    900: "#064C2E",
  },
};

const fonts = {
  // heading: "Raleway, sans-serif",
  // body: "Raleway, sans-serif",
  // heading: "AcariSansRegular",
  // body: "AcariSansRegular",
  heading: "WorkSansBlack",
  // heading: "WorkSansThin",
  body: "WorkSansExtraLight",
};

const backgroundGradient = {
  bGradient:
    // "linear(90deg, hsla(197, 14%, 57%, 1) 0%, hsla(192, 17%, 94%, 1) 100%)",
    // "linear(90deg, hsla(197, 14%, 57%, 1) 0%, hsla(192, 17%, 94%, 1) 100%)",
    "linear(red.100 0%, orange.100 25%, yellow.100 50%)",
};

// font-family: 'AcariSansRegular';
//   font-weight: normal;
//   font-style: normal;

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const Prose = {
  Prose: withProse(),
};

const customTheme = extendTheme(
  { 
    colors: {},
    breakpoints: {
      sm: "320px",
      md: "768px",
      lg: "960px",
      xl: "1200px",
    },
    config: {},
    fonts: {
      heading: "WorkSansThin",
      body: "WorkSansExtraLight",
    }, 
    backgroundGradient : {
      bGradient: "linear(red.100 0%, orange.100 25%, yellow.100 50%)",
    },
  },
  withProse()
);

const theme = extendTheme({ customTheme });

export default customTheme;
