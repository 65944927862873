import React from 'react';
import { Icon } from '@chakra-ui/react'
import { AiOutlineCopyrightCircle } from 'react-icons/ai'
import { Box, Button, Flex, HStack, Link, Spacer, Image, Text } from '@chakra-ui/react';

const Footer = () => {
  return (
    <Flex border='1px' borderColor='whiteAlpha.400' justify='center' px='6' py='3' align='center'>
      <HStack as='ul' listStyleType='none' >
        <Icon as={AiOutlineCopyrightCircle}/><Text fontSize='xl' fontWeight='bold'>2023 BizAlertPro. All Rights Reserved.</Text>
      </HStack>
    </Flex>
  )
}

export default Footer;