import React from 'react';
import { BiChevronRight } from 'react-icons/bi'
import { Box, Divider, Heading, HStack, Text, Stack, VStack, Button, Container, Flex, Image, Icon, Center, Highlight } from '@chakra-ui/react';
import { PopupButton } from '@typeform/embed-react'

const TypeForm = () => {
  return (
    <PopupButton id="XJpWYRYC" style={{ fontSize: 20 }} className="my-button">
      <Box
        py='4'
        >
        <Button 
          rightIcon={<BiChevronRight />} 
          size='lg'
          bg='#1A1B48' 
          px={6}
          color='whiteAlpha.900'
          _hover={{
            backgroundColor: 'white',
            // backgroundColor: 'transparent',
            // border: '#1A1B48',
            color: 'black',
            borderColor: '#1A1B48'
        }}>
          Get Started
        </Button>
      </Box>
    </PopupButton>
  )
}

const HeaderTwo = () => {
  return (
    <>
    <Container maxW='7xl'>
      <Stack
        align='center'
        spacing={{ base: 8, md: 10 }}
        direction={{ base: 'column', md: 'row' }}>
        <Stack 
        flex={1} 
        spacing={{ base: 6, md: 11 }} 
        // textAlign='center'
        align='center' 
        direction='column' >
          <Heading
            lineHeight={1.1}
            fontSize={{ base: '4xl', sm: '5xl', lg: '7xl' }}
            textAlign='center'
            fontWeight='extrabold'
            // fontSize={{ base: '5xl', sm: '6xl', lg: '8xl' }}
            >
            <Text
              // fontWeight='extrabold'
              // bgGradient='linear(90deg, #1A1B48, #FF5700, hsla(192, 17%, 94%, 1) 100%)'
              // bgGradient={'linear(90deg, hsla(197, 14%, 57%, 1) 0%, hsla(192, 17%, 94%, 1) 100%)'}
              // bgClip='text'
              as='span'
              position='relative'
              sx={{
                wordWrap: "normal"
              }}
              _after={{
                content: "''",
                width: 'full',
                height: '30%',
                position: 'absolute',
                bottom: 1,
                left: 0,
                // bg: 'red.400',
                zIndex: -1,
              }}>
                The Fix Is In.
              {/* Reddit Reputation Management And */}
              {/* One Reddit Post Can */}
              {/* Your Marketing Team Is */}
            </Text>
            <br />
            <Text 
              // as='span' 
              color='#FF5700'
              sx={{
                wordWrap: "normal"
              }}
              // bgGradient='linear(to-r, #1A1B48, #FF5700)'
              // bgGradient='linear(to-t, #1A1B48, #1A202C, #FFFFFF)'
              bgGradient='linear(to-br, #1A202C, #1A1B48)'
              bgClip='text'
              _after={{
                content: "''",
                width: 'full',
                height: '30%',
                position: 'absolute',
                bottom: 1,
                left: 0,
                // bg: 'red.400',
                zIndex: -1,
              }}
              >
              {/* Of Your Brand */}
              {/* Destroy Your Brand */}
              Crisis Averted.
            </Text>
            <Text 
              as='span' 
              color='#1A202C'
              sx={{
                wordWrap: "normal"
              }}
              // bgGradient='linear(to-t, #1A1B48, #1A202C, #FFFFFF)'
              // bgGradient='linear(to-tr, #1A1B48, #1A202C)'
              // bgGradient='linear(to-br, #1A1B48, #1A202C, #FF5700)'
              // bgClip='text'
              _after={{
                content: "''",
                width: 'full',
                height: '30%',
                position: 'absolute',
                bottom: 1,
                left: 0,
                // bg: 'red.400',
                zIndex: -1,
              }}
              >
              {/* Of Your Brand */}
              {/* Destroy Your Brand */}
              Brand Protected.
            </Text>
          </Heading>
          {/* <Text textAlign='center' as='span' fontSize='3xl' fontWeight='extrabold'>Daily Alerts<Text as='span' textDecoration='underline' textDecorationColor='#FF5700' fontSize='4xl'> ANYTIME </Text> */}
          <Text textAlign='center' as='span' fontSize='3xl' fontWeight='extrabold'>Daily Alerts<Text as='span' textDecoration='underline' textDecorationColor='#FFFFFF' fontSize='4xl'> ANYTIME </Text>
            Your Brand Is Mentioned On Reddit.
          </Text>
            {/* <Text as='span' fontSize='3xl' fontWeight='extrabold'> */}
              {/* <Highlight query={[' ANY ']} styles={{ rounded:'lg', px: '3', py: '1', bg: '#FF5700' }}> */}
              {/* </Highlight> */}
            {/* </Text> */}
          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={{ base: 'column', sm: 'row' }}>
            <TypeForm />
          </Stack>
        </Stack>
        <Flex
        flex={1}
        justify='center'
        align='center'
        position='relative'
        w='full'
        />
          <Box
            position='relative'
            height={{base: '40%', md: '70%', lg: '100%'}}
            // height={{base: '25%', md: '40%', lg: '60%'}}
            // height={{base: '25%', md: '400px'}}
            rounded='2xl'
            boxShadow='2xl'
            width={{base: '100%', md: '100%', lg: '4xl'}}
            overflow='hidden' 
            >
            <Image
              alt='Hero Image'
              // fit='contain' 
              fit='full' 
              borderRadius='lg'
              align='center'
              w='100%'
              h='100%'
              src='https://campfire-landing-page-assets.s3.us-east-2.amazonaws.com/hero_iteration_3_wm_rm.png'
            />
          </Box>
        </Stack>
      </Container>
    <Divider orientation='horizontal' pt='8' />
    </>
  )
}


const Blob = (props) => {
  return (
    <Icon
      width='100%'
      viewBox="0 0 578 440"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default HeaderTwo;