import React from 'react';
import {
  Box,
  Button,
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  IoAnalyticsSharp,
  IoLogoBitcoin,
  IoSearchSharp,
} from 'react-icons/io5';
import { BiChevronRight } from 'react-icons/bi'
import { PopupButton } from '@typeform/embed-react'

const TypeForm = () => {
  return (
    <PopupButton id="XJpWYRYC" style={{ fontSize: 20 }} className="my-button">
      <Box
        py='4'
        >
        <Button 
          rightIcon={<BiChevronRight />} 
          size='lg'
          bg='#1A1B48' 
          color='whiteAlpha.900'
          _hover={{
            backgroundColor: 'white',
            // backgroundColor: 'transparent',
            color: 'black',
            // border: '#1A1B48',
            borderColor: '#1A1B48'
        }}>
          Get My First Alert
        </Button>
      </Box>
    </PopupButton>
  )
}


const Feature = ({ text, icon, iconBg }) => {
  return (
    <Stack direction='row' align='center'>
      <Flex
        w={8}
        h={8}
        align='center'
        justify='center'
        rounded='full'
        bg={iconBg}>
        {icon}
      </Flex>
      <Text color='blackAlpha.900' fontWeight='extrabold'>{text}</Text>
    </Stack>
  );
};

export default function Features() {
  return (
    <Container maxW='6xl' py={6}>
      <SimpleGrid columns={{ base: 1, md: 2 }}  spacing={3} pb='5'>
        <Stack spacing={0} pt='12'>
          <Heading>You Control The Alerts</Heading>
          <Stack
            spacing={4}
            divider={
              <StackDivider
                borderColor={useColorModeValue('gray.100', 'gray.700')}
              />
            }>
          </Stack>
          <Feature
              icon={
                <Icon as={IoSearchSharp} color='whiteAlpha.500' w={8} h={8} />
              }
              iconBg={useColorModeValue('blue.600', 'blue.900')}
              text='Active Keyword Monitoring'
            />
          <Text  fontSize='xl' fontWeight='extrabold'>
          BizAlertPro searches Reddit extensively to return the most up-to-date mention activity
          surrounding your chosen keywords. Let us do the hard work of cleaning and compiling the data.
          </Text>
        </Stack>
        <Flex>
          <Image
            rounded='md'
            alt='feature image'
            src='https://campfire-landing-page-assets.s3.us-east-2.amazonaws.com/reddit.jpg'
            objectFit='cover'
          />
        </Flex>
      </SimpleGrid>


      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} pt='5'>
        <Flex>
            <Image
              rounded='md'
              alt='feature image'
              src='https://campfire-landing-page-assets.s3.us-east-2.amazonaws.com/public_relations.jpeg'
              objectFit='cover'
            />
          </Flex>
        <Stack spacing={0} pt='12'>
          <Heading>Keeping Your Customers Informed Pays Off</Heading>
          <Feature
            icon={
              <Icon as={IoAnalyticsSharp} color='yellow.500' w={5} h={5} />
            }
            iconBg={useColorModeValue('yellow.100', 'yellow.900')}
            text='Business Planning'
          />
          <Text fontSize='xl' fontWeight='extrabold'>
          Keep your finger on the pulse of your audience and understand the conversation around your brand
          on a deeper level. Eventually, you'll always be ahead of bad press and unruly online customers. 
          </Text>
          <Stack
            spacing={4}
            divider={
              <StackDivider
                borderColor={useColorModeValue('gray.100', 'gray.700')}
              />
            }>
          </Stack>
        </Stack>
      </SimpleGrid>


      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} py='12'>
        <Stack spacing={0} pt='12'>
          <Heading>Active Listening Saves Time & Money</Heading>
          <Feature
            icon={
              <Icon as={IoSearchSharp} color='purple.500' w={5} h={5} />
            }
            iconBg={useColorModeValue('purple.100', 'purple.900')}
            text='Market Research'
          />
          <Text fontWeight='extrabold' fontSize='xl'>
            Avoid missed opportunities to convert clients. Listen to what your customers are discussing to give you an edge in feature optimization and meeting
            them where they are headed.
          </Text>
          <Stack
            spacing={4}
            divider={
              <StackDivider
                borderColor={useColorModeValue('gray.100', 'gray.700')}
              />
            }>
          </Stack>
        </Stack>
        <Flex>
          <Image
            rounded='md'
            alt='feature image'
            src='https://campfire-landing-page-assets.s3.us-east-2.amazonaws.com/strategy.jpeg'
            objectFit='cover'
          />
        </Flex>
      </SimpleGrid>
      <Box align='center'>
        <TypeForm />
      </Box>
    </Container>
  );
}