import React from 'react';
import { Box, Button, Flex, HStack, Link, Spacer, Image } from '@chakra-ui/react';
import { useAnimation } from '@codechem/chakra-ui-animations';
import { PopupButton } from '@typeform/embed-react';

const TypeForm = () => {
  const animation = useAnimation('pulse', { duration: 1000, iterationCount: 'infinite' });
  return (
    <PopupButton id="XJpWYRYC" style={{ fontSize: 20 }} className="my-button">
      <Box
        py='4'
        >
        <Button 
          as='a'
          size='lg'
          variant='outline'
          bg='#1A1B48' 
          // borderColor='#FC6400'
          borderColor='white'
          color='whiteAlpha.900'
          _hover={{
            borderColor: 'red',
            bg: '#1A1B48',
            // background: 'transparent',
            color: 'white',
          }}
          animation={animation}
        >
          Try It Out
        </Button>
      </Box>
    </PopupButton>
  )
}

const TypeFormTwo = () => {
  return (
    <PopupButton id="BkIqx8t9" style={{ fontSize: 20 }} className="my-button">
      <Box
        py='4'
        >
          <NavLink href='#'>2023 Reddit Brand Rankings Report</NavLink>
      </Box>
    </PopupButton>
  )
}

const navLinks = [
  // {label: 'Home', link: '/'},
  // // {label: 'Features', link: '/#'},
  // {label: 'Contact', link: '/contact'},
  // {label: '2023 Brand Rankings', link: '/#'},
];


const NavLink = ({children}) => {
  const animation = useAnimation('pulse', { duration: 1000, iterationCount: 'infinite' });

  return(
    <Link 
    animation={animation}
    fontWeight={'bold'}
    fontSize='lg'
    _hover={{
      color:'#FC6400'
    }}to='/'>
      {children}
    </Link>
  );
}

const NavBar = () => {
  return (
    <Flex justify='space-between' px='6' py='3' align='center'>
      <HStack as='ul' listStyleType='none' >
        <Image
            // boxSize='100px'
            // maxW='25%'
            maxW='40%'
            // objectFit='cover'
            objectFit='contain'
            src='https://campfire-landing-page-assets.s3.us-east-2.amazonaws.com/logo-bizalert.png'
            alt='Brand Logo'
        />
        {navLinks.map((item) => (
          <li key={item.link}>
            <NavLink href={item.link}>{item.label}</NavLink>
          </li>
        ))}
      </HStack>
      <HStack  spacing='5'>
        {/* <TypeFormTwo /> */}
        <TypeForm />
        {/* <Button 
          as='a'
          variant='outline'
          borderColor='#243748'
          // color='white'
          _hover={{
            borderColor: 'white',
            bg: '#1A1B48',
            // background: 'transparent',
            color: 'white',
        }}
        >
          Free Trial
        </Button> */}
      </HStack>
    </Flex>
  )
}

const NavBar2 = () => {
  return (
    <Box as='nav' py='4'>
    {/* <Box as='nav' bg='#1A1B48' color='white' py='4'> */}
      <Box  maxW={{ base: '3xl', md:'300xl'}} mx='auto' fontSize='18px'>
        <HStack spacing='40'>
          <Flex justify='space-between' px='6' py='5' align='center'>
            <HStack as='ul' listStyleType='none' >
              {navLinks.map((item) => (
                <li key={item.link}>
                  <NavLink href={item.link}>{item.label}</NavLink>
                </li>
              ))}
            </HStack>
            <HStack px='10' spacing='140'>
              <NavLink href='#'>Testimonials</NavLink>
              <Button 
                as='a'
                variant='outline'
                borderColor='#243748'
                // color='white'
                _hover={{
                  borderColor: 'white',
                  background: 'transparent',
                  // color: 'white',
              }}
              >
                Free Trial
              </Button>
            </HStack>

            {/* <Flex>
              <HStack as='ul' listStyleType='none' >
                {navLinks.map((item) => (
                  <li key={item.link}>
                    <NavLink href={item.link}>{item.label}</NavLink>
                    <Spacer />
                  </li>
                ))}
              </HStack>
              <HStack spacing='40'>
                <NavLink>Sign In</NavLink>
                <Button 
                  as='a'
                  variant='outline'
                  borderColor='#FC6400'
                  _hover={{
                    borderColor: 'white',
                    background: 'transparent',
                    // color: 'white',
                }}
                >
                  Get Started
                </Button>
              </HStack>
            </Flex> */}

            {/* <Flex>
              <Box w='70px' h='10' bg='red.500' />
              <Spacer />
              <Box w='170px' h='10' bg='red.500' />
              <Spacer />
              <Box w='180px' h='10' bg='red.500' />
            </Flex> */}

          </Flex>
        </HStack>
      </Box>
    </Box>
  )
}

export default NavBar